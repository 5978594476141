import React from 'react';
import './Info.css';
import retoEntrenamiento from '../../../images/infoImages/retos_de_entrenamiento.svg';
import cursoEntrenamiento from '../../../images/infoImages/cursos_de_entrenamiento.svg';
import comunidadValky from '../../../images/infoImages/comunidad_valkyfamilia.svg';
import sesionesVivo from '../../../images/infoImages/sesiones_en_vivo.svg';
import cursosNutricion from '../../../images/infoImages/cursos_de_nutricion.svg';
import htasNutricion from '../../../images/infoImages/herramientas_nutricionales.svg';
import cursosGluteos from '../../../images/infoImages/cursos_gluteos.svg';
import yoga from '../../../images/infoImages/yoga.svg';
import bajoImpacto from '../../../images/infoImages/bajo_impacto.svg';
import entrenamientoPersonalizado from '../../../images/infoImages/entrenamiento_personalizado.svg';
import cursoPilates from '../../../images/infoImages/curso_pilates.svg';
import crecimientoPersonal from '../../../images/infoImages/crecimiento_personal.svg';

import appleStore from '../../../images/appleStore.png';
import googlePlay from '../../../images/googlePlay.png';

import { getAnalytics, logEvent } from 'firebase/analytics';

import {app} from '../../../firebase';

const analytics = getAnalytics(app);

function Info() {
  const handleClick = (store) => {
    logEvent(analytics, 'action_info_to_' + store.target.id);
  }
  return (
      <main className='infoSection'>
          <section className='infoLeftSection'>
            <h1>¡TODO LO QUE NECESITAS PARA UN ESTILO DE VIDA SALUDABLE!</h1>
            <h3>Descubre una comunidad fitness completa en un solo lugar</h3>
            <p>
              Si estás listo para cambiar tu vida, bajar de peso, mejorar tu salud y ser más saludable, has llegado al lugar adecuado. ¡Bienvenido a nuestra comunidad de bienestar!
            </p>
            <p>
              Solo necesitas mucha actitud y voluntad para lograrlo.
            </p>
          </section>
          <section className='infoRightSection'>
            <h5 className='rightMobiletitle'>¡TODO LO QUE NECESITAS PARA UN ESTILO DE VIDA SALUDABLE!</h5>
            <div className='infoLeftRow1'>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon' src={retoEntrenamiento} alt='retos de entrenamiento'/>
                <p>Retos de entrenamiento</p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={cursoEntrenamiento} alt='cursos de entrenamiento'/>
                <p>Cursos de entrenamiento</p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={comunidadValky} alt='comunidad'/>
                <p>Comunidad “Valkyfamilia”</p>
              </div>
            </div>
            <div className='infoLeftRow1'>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={sesionesVivo} alt='clases en vivo'/>
                <p>Sesiones en vivo</p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={cursosNutricion} alt='cursos de nutrición'/>
                <p>Cursos de nutrición </p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={htasNutricion} alt='herramientas nutricionales'/>
                <p>Herramientas nutricionales”</p>
              </div>
            </div>
            <h6 className='infoRighSubtitle'>¡MUCHO MÁS POR VENIR!</h6>
            <div className='infoLeftRow1'>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={cursosGluteos} alt='cursos para glúteo'/>
                <p>Cursos para glúteo</p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={yoga} alt='yoga'/>
                <p>Yoga</p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={bajoImpacto} alt='ejercicio de bajo impacto'/>
                <p>Bajo impacto”</p>
              </div>
            </div>
            <div className='infoLeftRow1'>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={entrenamientoPersonalizado} alt='entrenamientos personalizados'/>
                <p>Entrenamientos personalizados</p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={cursoPilates} alt='cursos de Pilates'/>
                <p>Cursos de Pilates</p>
              </div>
              <div className='infoLeftIconContainer'>
                <img className='infoIcon'  src={crecimientoPersonal} alt='crecimiento personal '/>
                <p>Crecimiento personal ”</p>
              </div>
            </div>
            <div className='rightMobileSubtitle'>
              <h3>Descubre una comunidad fitness completa en un solo lugar</h3>
              <p>
                Si estás listo para cambiar tu vida, bajar de peso, mejorar tu salud y ser más saludable, has llegado al lugar adecuado. ¡Bienvenido a nuestra comunidad de bienestar!
              </p>
              <p>
                Solo necesitas mucha actitud y voluntad para lograrlo.
              </p>
            </div>
            <div className='dividerInfo'></div>
            <section className='storeLinks'>
                <a href="https://apps.apple.com/us/app/valkybox/id6445876727" target='_blank'>
                    <img id='apple' src={appleStore} className='storeImage' alt="profile" onClick={handleClick}/>
                </a>
                <div className='divider'></div>
                <a href="https://play.google.com/store/apps/details?id=com.valkybox.valkybox&pli=1" target='_blank'>
                  <img id='android' src={googlePlay} className='storeImage' alt="Google Play" onClick={handleClick}/>
                </a>
            </section>
            
          </section>
      </main>
      
    
  );
}

export default Info;