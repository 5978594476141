import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Registration from "./Views/Registration/Registration";
import Home from "./Views/Home/Main";
import Store from "./Views/AppStore/AppStore";

export default function App() {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/registro" element={<Registration/>}/>
          <Route exact path="/store" element={<Store/>}/>
        </Routes>
    </Router>
  );
}
