// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDfyic4UIpC_fFItRmVDhlHE1rY-EA9_Y",
  authDomain: "landing-valky.firebaseapp.com",
  projectId: "landing-valky",
  storageBucket: "landing-valky.appspot.com",
  messagingSenderId: "645289848010",
  appId: "1:645289848010:web:84d37832ef4388a84c5833",
  measurementId: "G-6VMR5HQT6T"
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);