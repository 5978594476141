import React, {useRef, useEffect} from "react"

import './Nutrition.css';
import appleStore from '../../../images/appleStore.png';
import googlePlay from '../../../images/googlePlay.png';
import backImage from '../../../images/mainImage.svg';

import { getAnalytics, logEvent } from 'firebase/analytics';

import {app} from '../../../firebase';

import phnegif from '../../../images/phone.gif';

import videoUrlMov from '../../../images/phone.mov';
const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2Fphone.webm?alt=media&token=3ea78afa-d45c-459a-8d65-c1aaad35ef88.webm';
const videoUrlMp4 = 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2Fphone.mp4?alt=media&token=93811a50-5bca-43a1-b263-a288a1e0f456.mp4';
const analytics = getAnalytics(app);

function Intro() {
  return (
    <div className='mainSection'>
        <header className='header'>
        <img
          className='logo'
          src='https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2FvalkyeEmailLogo.png?alt=media&token=fd469181-c77f-46ec-a477-2041f45213f4'
          alt='App fitness de entrenamiento'
        />
      </header>
      <main className="fullPageBackgroundNutrition">
      <video autoPlay muted loop>
            <source src="https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2Fgif_david.mp4?alt=media&token=5e27809c-b861-45fd-bf93-abbd9796b7f4.mp4" type="video/mp4" />
        </video>
        <section>
          <div className='mainLeftBody'>
            <img className="imageGif" src="https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2FnutritionScreen.png?alt=media&token=b6e798f6-6c19-48e2-b66f-4915e0f00ef4" />
          </div>
          <div className='mainRightBody mainRightBodyNutrition'>
            <h2 className="mainRightBodyNutritionTitle">¿DIETA PARA BAJAR DE PESO? </h2>
            <div className='mainLeftBodyMobile'>
                <img className="imageGif" src="https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2FnutritionScreen.png?alt=media&token=b6e798f6-6c19-48e2-b66f-4915e0f00ef4" />
            </div>
            <article>
              <p>
              !Dile adiós a las dietas! Aprende como alimentarte correctamente, Planea tu alimentación de la semana y podrás empezar a ser más saludable.
              </p>
            </article>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Intro;
