import React from 'react';
import { Helmet } from 'react-helmet';

import Intro  from "./Intro/Intro";
import Info  from "./Info/Info";
import Nutrition  from "./Nutrition/Nutrition";
import Reviews  from "./Reviews/Reviews";

import './Main.css';

function Home() {
  return (
    <div>
      <Helmet>
        <title>Hacer ejercicio va a cambiar tu vida</title>
        <meta name="description" content="Aprende a mejorar tus hábitos, bajar de peso y vivir saludablemente con nuestra aplicación de fitness. Ofrecemos contenido de calidad, entrenamientos y consejos para tu transformación." />
        <meta name="keywords" content="fitness, salud, bienestar, bajar de peso, hábitos saludables, entrenamientos, nutrición" />
        <meta name="author" content="valkybox" />
        {/* Agrega otras etiquetas meta relevantes, como imagen compartida en redes sociales */}
      </Helmet>

      <div className='mainSections'>
        <Intro />
        <Info />
        <Nutrition />
        <Reviews />
      </div>
      
    </div>
  );
}

export default Home;