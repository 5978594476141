import React, { useState } from 'react';
import logoHeader from '../../images/logoHeader.png';
import mainImage from '../../images/mainImage.png';
import './Registration.css';
import { collection, addDoc } from "firebase/firestore";
import {app} from '../../firebase';
import { getFirestore } from "firebase/firestore";

function Registration() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [place, setPlace] = useState('');

  const [sendText, setSendText] = useState('ENVIAR');

  const [cell, setCell] = useState('');

  const onChangeName = event => {
    setName(event.target.value);
  }
  const onChangeEmail = event => {
    setEmail(event.target.value);
  }
  const onChangeCelular = event => {
    setCelular(event.target.value);
  }

  const onChangePlace = event => {
    setPlace(event.target.value);
  }

  const sendMessage = async () => {
    console.log('here');
      if(name !== '' && email !== '' && celular !== '' && cell !== '' && place !== '' && sendText !== 'ENVIADO'){
        console.log('there');
          try {
              setSendText('...')
              const docRef = await addDoc(collection(getFirestore(app), "registro"), {
                'name': name,
                'email': email,
                'celular': celular,
                'place': place,
                'celularType': cell
              });
              if(docRef.id !== null){
                  setSendText('ENVIADO');
              }
            } catch (e) {
              console.error("Error adding document: ", e);
            }
      }
     
  };

  return (
    <div>
     <div className='mobileResponsive'>
        <div className='headerHome'>
          <img src={logoHeader} className='logoHeaderHome' />
        </div>
        <div className='subHeaderHome'>
          <p className='titleHome'>!EMPIEZA A CAMBIAR TU VIDA !</p>
        </div>
        <div className='imageContainer'>
          <img src={mainImage} className='valkyImage' />
        </div>
        <div className='modalFormContainerRegistro'>
              <div className='modalFormRegistro'>
                <div>
                  <input className='inputField' placeholder='Nombres' onChange={(event) => onChangeName(event)} />
                  <input className='inputField' placeholder='Correo' onChange={(event) => onChangeEmail(event)}/>
                  <input className='inputField' placeholder='De dónde eres?' onChange={(event) => onChangePlace(event)}/>
                  <input className='inputField' placeholder='Celular' onChange={(event) => onChangeCelular(event)} />
                  <div className='cellTypeContainer'>
                    <div className='cellType' onClick={() =>setCell('ios')} style={cell === 'ios' ? {backgroundColor: '#00FFE0'}: {backgroundColor: 'white'}}>iOS</div>
                    <div className='cellType' onClick={() =>setCell('android')} style={cell === 'android' ? {backgroundColor: '#00FFE0'}: {backgroundColor: 'white'}}>Android</div>
                  </div>
                  <div className='registerButton' onClick={sendMessage} style={ sendText === 'ENVIADO' ? {color: '#F75197'} : {backgroundColor: (email === '' || celular === '' || name === '' || cell === '' || place === '') ? '#DADADA' : null}}>{sendText}</div>
                </div>
              </div>
              <div className='politicasContainer'>
              </div>
          </div>
        <div className='subtitleHomeContainerRegistro'>
        
          <p className='subtitleHome'>Entrena desde cualquier lugar del mundo, sin horarios y sin excusas</p>
        </div>
        <p className='adviceHome'>CONSTRUYE TU MEJOR VERSIÓN </p>
      </div>
      <div className='desktop'>
        <div className='headerHome'>
          <img src={logoHeader} className='logoHeaderHome' />
          <img src={mainImage} className='valkyImageHome' />
        </div>
        <div className='rightContainerHome'>
          <div className='topRightHome'>
            <p className='titleHome'>!EMPIEZA A CAMBIAR TU VIDA !</p>
            <p className='subtitleHome'>Entrena desde cualquier lugar del mundo, sin horarios y sin excusas.</p>
          </div>
          <div className='modalFormContainerRegistro'>
              <div className='modalFormRegistro'>
                <div>
                  <input className='inputField' placeholder='Nombres' onChange={(event) => onChangeName(event)} />
                  <input className='inputField' placeholder='Correo' onChange={(event) => onChangeEmail(event)}/>
                  <input className='inputField' placeholder='De dónde eres?' onChange={(event) => onChangePlace(event)}/>
                  <input className='inputField' placeholder='Celular' onChange={(event) => onChangeCelular(event)} />
                  <div className='cellTypeContainer'>
                    <div className='cellType' onClick={() =>setCell('ios')} style={cell === 'ios' ? {backgroundColor: '#00FFE0'}: {backgroundColor: 'white'}}>iOS</div>
                    <div className='cellType' onClick={() =>setCell('android')} style={cell === 'android' ? {backgroundColor: '#00FFE0'}: {backgroundColor: 'white'}}>Android</div>
                  </div>
                  <div className='registerButton' onClick={sendMessage} style={ sendText === 'ENVIADO' ? {color: '#F75197'} : {backgroundColor: (email === '' || celular === '' || name === '' || cell === '' || place === '') ? '#DADADA' : null}}>{sendText}</div>
                </div>
              </div>
              <div className='politicasContainer'>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;