import React, {useRef, useEffect} from "react"

import './Intro.css';
import appleStore from '../../../images/appleStore.png';
import googlePlay from '../../../images/googlePlay.png';
import backImage from '../../../images/mainImage.svg';

import { getAnalytics, logEvent } from 'firebase/analytics';

import {app} from '../../../firebase';

const mainIamge = 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2FfullbodyIntro.jpg?alt=media&token=fae6e489-64b3-4848-9c7a-6e69bbfe8313';

const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2Fphone.webm?alt=media&token=3ea78afa-d45c-459a-8d65-c1aaad35ef88.webm';
const videoUrlMp4 = 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2Fphone.mp4?alt=media&token=93811a50-5bca-43a1-b263-a288a1e0f456.mp4';
const analytics = getAnalytics(app);

function Intro() {

  var EmbedVideo = function(props) {
    return (
        <div dangerouslySetInnerHTML={{ __html: `
         <video
           loop
           muted
           autoplay
           playsinline
           src="${videoUrlMp4}"
           class="imageGif"
         />,
       ` }}></div>
    )
 }

  const handleClick = (store) => {
    logEvent(analytics, 'action_intro_to_' + store.target.id);
  }
  return (
    <div className='mainSection'>
      <header className='header'>
        <img
          className='logo'
          src='https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2FvalkyeEmailLogo.png?alt=media&token=fd469181-c77f-46ec-a477-2041f45213f4'
          alt='App fitness de entrenamiento'
        />
        <h1 className='headerTitle'>¡EL MOMENTO ES AHORA!</h1>
      </header>
      <img src={mainIamge} style={{display: 'none'}}/>
      <main className="fullPageBackground">
        <section>
          <div className='mainLeftBody'>
            <video autoPlay loop muted className="imageGif">
              <source src={videoUrl} type="video/webm" />
            </video>
          
          </div>
          <div className='mainRightBody'>
            <h2>¡HACER EJERCICIO CAMBIARÁ TU VIDA!</h2>
            <div className='mainLeftBodyMobile'>
                <div dangerouslySetInnerHTML={{ __html: `
                <video
                  loop
                  muted
                  autoplay
                  playsinline
                  src="${videoUrlMp4}"
                  class="imageGif"
                />,
              ` }}>
              </div>
            </div>
            <article>
              <p>
                Entrena desde cualquier lugar del mundo, sin horarios y sin excusas.
              </p>
              <p>
                No importa tu nivel, con o sin material.
              </p>
              <p>
                Gestiona tu alimentación y aprende a cuidarte con la guía de expertos.
              </p>
            </article>
            <section className='storeLinks'>
              <a href="https://apps.apple.com/us/app/valkybox/id6445876727" target='_blank'>
                <img id='apple' src={appleStore} className='storeImage' alt="Descarga en App Store" onClick={handleClick}/>
              </a>
              <div className='divider'></div>
              <a href="https://play.google.com/store/apps/details?id=com.valkybox.valkybox&pli=1" target='_blank'>
                <img id='android' src={googlePlay} className='storeImage' alt="Descarga en Google Play" onClick={handleClick}/>
              </a>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Intro;
