import React from 'react';

import { Link } from 'react-router-dom';

import mainImage from '../../images/trenInferior.jpg';

import appleStore from '../../images/appleStore.png';
import googlePlay from '../../images/googlePlay.png';
import './AppStore.css';

function AppStore() {
  return (
    <div className='containerStore'>
      <div className='imageContainer'>
        <img src={mainImage} className='profileImage' alt="profile" />
      </div>
      <div className='centerContainer'>
        <a className='descriptionContainer' href="https://www.instagram.com/valkyapp/">@valkyapp</a>
      </div>
      <div className='centerContainer'>
        <p className='descriptionContainer'>
            !CONSTRUYE TU MEJOR VERSIÓN! 
        </p>
      </div>
      <div className='centerContainer'>
        <p className='descriptionContainer' style={{fontWeight: '400'}}>
        Entrena desde cualquier lugar del mundo, sin horarios, sin excusas y aprende cómo tener una vida más saludable.
        </p>
      </div>
      <div className='imageContainerStore'>
        <a href="https://apps.apple.com/us/app/valkybox/id6445876727">
            <img src={appleStore} className='storeImage' alt="profile" />
        </a>
      </div>
      <div className='imageContainerStore'>
        <a href="https://play.google.com/store/apps/details?id=com.valkybox.valkybox&pli=1">
          <img src={googlePlay} className='storeImage' alt="Google Play" />
        </a>
      </div>
      <div className='imageContainerStore'>
        <Link to="/" className='linkToHome'>
          <p>IR AL WEBSITE</p>
        </Link>
      </div>
    </div>
    
  );
}

export default AppStore;