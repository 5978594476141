import React from "react"

import './Reviews.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


const items = [
  {
    name: 'Alejandra Londoño',
    description: 'Hoy es mi primer día    retomando después de un postoperatorio.... estuvo muy bueno, gracias!!!!',
    image: 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/comments%2Falejandra.png?alt=media&token=143a0262-519d-4af6-91bd-d3bb21ff2650'
  },
  {
    name: 'Diego Rojas',
    description: 'Está súper, ya voy para la segunda semana, está uno full, la verdad me sirvió mucho ',
    image: 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/comments%2Fdiego.png?alt=media&token=2b7ecd24-d702-46e8-a2e6-3c5bc12dfc43'
  },
  {
    name: 'Nathaly García',
    description: 'Acabé de terminar mi primera semana gratis, estoy full animada.Muchas gracias Valky saludos desde Galicia España',
    image: 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/comments%2Fnathaly.png?alt=media&token=8db56ff1-1972-438d-abcd-34c74c419745'
  },
  {
    name: 'Jackeline Aguilar',
    description: 'Empecé desde 0 y acabo de terminar la tercera semana! se siente tan genial y la guía de Valky la mejor! SIGAMOS ADELANTE',
    image: 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/comments%2Fjackeline_aguilar.png?alt=media&token=5d1f56e2-1598-4f13-812c-9980dc19a78d5'
  },
  {
    name: 'Karen Paternina',
    description: 'He empezado a hacer ejercicios y me ha permitido tener mejor estado físico, seguiré no me rendiré... Gracias Valky!!!',
    image: 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/comments%2Fkaren_paternina.png?alt=media&token=62d3148e-62d2-444a-bc67-d170c04e95b8'
  },
  {
    name: 'Sujeidi Colombo',
    description: 'Gracias Valky por permitirme ser parte de esta hermosa experiencia, saludos desde Venezuela, eres una guerrera hermosa',
    image: 'https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/comments%2Fsujeidi.png?alt=media&token=c56ea181-274d-40f9-8325-65e9fe007942'
  }
];

function Intro() {

  return (
    <div className='mainSection'>
      <main className="fullPageBackgroundReview">
        <section className="reviewTitle">
          <h1>YA SOMOS +6000 CONSTRUYENDO NUESTRA MEJOR VERSIÓN</h1>
        </section>
        <div className="staticSlide">
          <div className="staticInnerSlide">
          <div className="slideProfile">
            <img alt="usuario que hace ejercicio para mejorar su salud" src='https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/comments%2Fnaty.png?alt=media&token=040ba732-8b74-4bb8-8e08-6b53b2ee38c2' className="profileCommnet" />
            <p className="profileName">Natalia Quiroz</p>
          </div>
          <p>Amooooo! Esta app y este equipo me ha ayudado a crecer enormemente no solo a nivel físico, si no mental! Incrementando mi seguridad! 
            GRACIAS INFINITAS
            !Se merecen todo lo bonito!</p>
          <div className="spacerCarousel"></div>
          </div>
          
        </div>

        <div className="carouselContainer">
          <Carousel useKeyboardArrows={true} autoPlay infiniteLoop='true'>
            {items.map((item, index) => (
              <div className="slide">
                <section className="innerSlide">
                  <div className="slideProfile">
                    <img alt="usuario que hace ejercicio para mejorar su salud" src={item.image} className="profileCommnet" />
                    <p className="profileName">{item.name}</p>
                  </div>
                  <p>{item.description}</p>
                  <div className="spacerCarousel"></div>
                </section>
                
              </div>
            ))}
          </Carousel>
        </div>
       
      </main>
    
    </div>
  );
}

export default Intro;
